import React from 'react'
import { Link } from 'gatsby'
import Layout from '../components/layout'
import { HowItWorks } from '../components/HowItWorks'
import { Services } from '../components/Services'
import { ContactUs } from '../components/ContactUs'
import { content } from '../content/content'

export default function Homepage() {
    return (
        <Layout>
            <div id='homepage--page'>
                <div id='homepage--component'>
                    <div id='homepage_banner'>
                        <div id='homepage_banner--content'>
                            <span id='homepage_banner--header'>WE<br /> <span className='emphasisText'>BUILD</span>, <span className='emphasisText'>CREATE</span>, <p id='banner--linebreak'>AND <span className='emphasisText'>DEVELOP</span> YOUR DIGITAL FOOTPRINT</p></span>
                            <span id='homepage_banner--subheader'>We are the only full-stack design and creation house serving Chattanooga</span>
                        </div>
                        <Link to='/Pricing/'>
                            <button id='homepage_banner--button'>Get Started</button>
                        </Link>
                    </div>
                    <HowItWorks />
                    <div id='homepage--slideshow'>
                        <h3>Portfolio Slideshow</h3>
                    </div>
                    <div id='services--title'>SERVICES</div>
                    <div id='services--seperator' className='seperator'></div>
                    <div className='homepage_box--component'>
                        <Services alt='homepageservices1' id={'services1'} title={content.services1.title} description={content.services1.description} url={content.services1.url} titleBack={content.services1.description} />
                        <Services alt='homepageservices2' id={'services2'} title={content.services2.title} description={content.services2.description} url={content.services2.url} titleBack={content.services2.description} />
                        <Services alt='homepageservices3' id={'services3'} title={content.services3.title} description={content.services3.description} url={content.services3.url} titleBack={content.services3.description} />
                    </div>
                    <div className='homepage_box--component'>
                        <Services alt='homepageservices4' id={'services4'} title={content.services4.title} description={content.services4.description} url={content.services4.url} titleBack={content.services4.description} />
                        <Services alt='homepageservices5' id={'services5'} title={content.services5.title} description={content.services5.description} url={content.services5.url} titleBack={content.services5.description} />
                        <Services alt='homepageservices6' id={'services6'} title={content.services6.title} description={content.services6.description} url={content.services6.url} titleBack={content.services6.description} />
                    </div>
                    <Link to='/Services/'><button id='learnMore_button'>Learn More</button></Link>
                    <div id='contactUs--title'>CONTACT US</div>
                    <div id='services--seperator' className='seperator'></div>
                    <div id='contactUs--container'>
                        <ContactUs />
                    </div>
                </div>
            </div>
        </Layout>
    )
}