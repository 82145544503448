import React from 'react'
import { Link } from 'gatsby'

export const HowItWorks = () => {
    return (
        <div id='howItWorks--component'>
            <div id='howItWorks_header'>
                <img id='howItWorks_header--img' alt='cmslogo.png' src='https://i.imgur.com/MSIHx6g.png'></img>
                <div id='howItWorks--description'>We provide easy to track and affordable media with strong brand messaging to build, promote, and grow your business for years to come. Collectively our team has extensive expertise in:</div>
            </div>
            <div id='howItWorks_service--component'>
                <div className='howItWorks_service--element'>
                    <img alt='contentcreatingimg' className='howItWorks--img' id='img--contentCreation' src='https://i.imgur.com/2f3y2IX.png'></img>
                    <Link to='/WebDesign/'>
                        <button className='howItWorks_service--description'>Content Creation</button>
                    </Link>
                </div>
                <div className='howItWorks_service--element'>
                    <img alt='marketingimg' className='howItWorks--img' id='img--marketing' src='https://i.imgur.com/cDUxo83.png'></img>
                    <Link to='/SEO/'>
                        <button className='howItWorks_service--description'>Marketing</button>
                    </Link>
                </div>
                <div className='howItWorks_service--element'>
                    <img alt='digitaldistributionimg' className='howItWorks--img' id='img--digitalDistribution' src='https://i.imgur.com/MV5B9pd.png'></img>
                    <Link to='/DigitalMarketing/'>
                        <button className='howItWorks_service--description'>Digital Distribution</button>
                    </Link>
                </div>
            </div>
        </div>
    )
}