import React from 'react'

export const Services = (props) => {
    return (
        <div className='flip-card'>
            <div className='flip-card-inner'>
                <div className='flip-card-front'>
                    <img alt={props.alt} id={`${props.id}--img`} className='services--img' src={props.url}></img>
                </div>
                <div className='flip-card-back'>
                    <div className='services--text'>
                        <p className='services--titleBack'>{props.titleBack}</p>
                    </div>
                </div>
            </div>
            <div className='services--text'>
                 <p className='services--title'>{props.title}</p>
            </div>
        </div>
    )
}